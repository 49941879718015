"use strict";

let width, height, canvas, ctx, circles, animateHeader = true;
let options = {
    'density': 10,
    'speed': 1.3
};

export function init() {
    if (matchMedia('(max-width: 767px)').matches) {
        return;
    }
    canvas = document.getElementsByClassName('js-hero-summer-animation__canvas')[0];
    initCanvas(canvas);
    addListeners();
}

function initCanvas(canvas) {
    resize();
    ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, width, height);

    createParticles();
    animate();
}

function createParticles() {
    circles = [];
    let amount = Math.floor(width / (10 / options.density));

    for (let x = 0; x < amount; x++) {
        circles.push({
            x: Math.random() * width, //x-coordinate
            y: (height-30) + Math.random() * 200, //y-coordinate
            r: Math.random() * 3.6, //radius,
            a: 0.1 + Math.random() * 0.4, //opacity,
            v: Math.random() //velocity
        });
    }
}

// Event handling
function addListeners() {
    window.addEventListener('scroll', scrollCheck);
    window.addEventListener('resize', resize);
}

function scrollCheck() {
    animateHeader = document.body.scrollTop <= height;
}

function resize() {
    canvas.height = document.querySelector('.js-hero-portal').clientHeight;
    canvas.width = window.innerWidth;
    width = canvas.width;
    height = canvas.height;
}

function animate() {
    ctx.clearRect(0, 0, width, height);

    if (animateHeader) {
        drawCircles(circles);
    }


    requestAnimationFrame(animate);
}

function drawCircles() {
    ctx.beginPath();

    for (let i in circles) {
        let circle = circles[i];
        ctx.beginPath();
        ctx.moveTo(circle.x, circle.y);
        // ctx.lineWidth = 1;
        // ctx.fillStyle = '#fff';
        // let path = new Path2D('M10.771,93l11.887-10.992c0.856-0.792,1.209-1.886,1.151-2.966l42.528-39.157c4.064,2.569,20.494,12.647,27.814,12.52  c1.04-0.018,1.867-0.874,1.849-1.915c-0.018-1.04-0.874-1.867-1.915-1.849c-3.624,0.063-11.067-3.512-17.425-7.033  c6.077,1.036,13.3,2.084,17.338,2.014c1.04-0.018,1.867-0.874,1.849-1.915c-0.018-1.04-0.874-1.867-1.915-1.849  c-3.392,0.059-9.433-0.773-14.942-1.681c5.34-0.74,10.944-1.989,13.259-4.09c0.773-0.697,0.824-1.888,0.128-2.659  c-0.697-0.769-1.888-0.828-2.659-0.128c-1.733,1.572-7.489,2.703-13.285,3.39c3.894-2.186,8.169-4.776,10.286-6.697  c0.769-0.701,0.824-1.886,0.128-2.659c-0.697-0.769-1.888-0.828-2.659-0.128c-2.284,2.072-7.696,5.187-11.905,7.473  c2.488-4.208,5.908-9.711,8.095-12.057c0.707-0.76,0.663-1.949-0.094-2.66c-0.761-0.709-1.951-0.667-2.66,0.094  c-1.888,2.026-4.49,5.985-6.769,9.698c1.035-5.385,2.448-10.583,4.015-12.264c0.707-0.76,0.665-1.951-0.094-2.66  c-0.761-0.709-1.951-0.667-2.66,0.094c-2.227,2.389-3.83,8.329-4.886,13.731c-0.635-5.811-1.238-12.687-1.027-16.338  c0.057-1.082-0.73-1.929-1.768-1.99c-1.038-0.061-1.929,0.73-1.99,1.768c-0.048,0.821-0.059,1.779-0.041,2.835  c0.063,3.607,0.47,8.361,0.925,12.706c-2.575-5.936-4.959-12.466-4.75-15.766c0.086-1.055-0.73-1.929-1.768-1.99  s-1.929,0.73-1.99,1.768c-0.014,0.236-0.017,0.48-0.013,0.736c0.125,7.179,7.275,21.804,9.523,26.231L21.714,75.856  c-1.349-0.641-3.003-0.452-4.167,0.623L5.66,87.471c-0.82,0.759-1.226,1.797-1.208,2.832c0.015,0.891,0.347,1.783,0.999,2.488  C6.864,94.318,9.244,94.411,10.771,93z');
        // ctx.fill(path);
        ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, true);
        ctx.fillStyle = "rgba(255, 255, 255, "+ circle.a +")";
        ctx.fill();
    }


    let angle = 0;

    for (let i in circles) {
        let circle = circles[i];
        circle.y -= circle.v;
        circle.a -= (height < 800) ? 0.0023 : 0.0013;

        //Checks if circles has left screen
        if (circle.a < 0) {

            if (i % 3 > 0) {
                circles[i] = {
                    x: Math.random() * width,
                    y: height,
                    r: circle.r,
                    a: 0.1 + Math.random() * 0.6,
                    v: Math.random() * (options.speed)
                };
            }
        }
    }
}
