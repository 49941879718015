"use strict";

import {registerPermissionGrantedCallback} from "@elements/cookie-permissions";

export function initInScope ($scope) {
    registerPermissionGrantedCallback('externalVideo', function () {
        let $videoAreas = $scope.find('.js-external-video-area');
        $videoAreas.each(function () {
            let $video = $(this).find('.js-external-video');
            let videoSrc = $video.data("src");
            $video.attr("src", videoSrc);
            let $overlay = $(this).find('.js-accept-cookies-overlay');
            $overlay.addClass('d-none');
        });
    });
}
