import loadScript from "@elements/load-script";

const API_KEY = '6LfuLZgaAAAAAL4WnUsx-U1E5TRn7-oPIyCUwB-R';

//recaptcha v3
export function initInScope($scope) {
    let $recaptcha = $scope.find('.js-recaptcha');

    if($recaptcha && $recaptcha.length > 0) {
        loadRecaptchaAPI().then(function () {
            console.log("RECAPTCHA LOADED"); 
            $recaptcha.toArray().forEach(element => {
                let isValidated = false;

                $(element).on('submit', function (evt) {
                    if (window['grecaptcha'] && !isValidated) {
                        evt.preventDefault();
                        grecaptcha.ready(function () {
                            let recaptchaAction = $(element).find('.js-recaptcha__action').val();
                            grecaptcha.execute(API_KEY, {action: recaptchaAction}).then(function (token) {
                                let recaptchaResponse = $(element).find('.js-recaptcha__response');
                                recaptchaResponse.val(token);
                                isValidated = true;
                                $(element).trigger('submit');
                            });
                        });
                    }
                });
            })
        })
    }

}

export function loadRecaptchaAPI() {
    return loadScript(`https://www.google.com/recaptcha/api.js?render=${API_KEY}`);
}
