import $ from 'jquery';

let app = window.app || {};
window.app = app || {};
window.app.modules =  app.modules || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

let selectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
};

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.createInitInScope(selectors, {
    preventNative: true
});

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as notificationHeader from '../scripts/notificationHeader';
app.notificationHeader = notificationHeader.init;

import * as affixNav from '../scripts/affixNav';
app.affixNav = affixNav.init;

import * as simpleNav from '../scripts/simple-nav';
app.simpleNav = simpleNav.init;

import * as menuAim from '../scripts/menuAim';
app.modules.menuAim = menuAim.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    addUrlParams: true
});

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import {UTCDateToLocalDate} from "@elements/date-utils";
//birthday datepicker needed to be overwritten due do different class names (datepicker package uses fixed clas names)
import * as birthdayDatepicker from '../scripts/bithdayDatepicker';
app.modules.birthdayDatepicker = birthdayDatepicker.createInitInScope({
    base: '.js-birthday-datepicker',
    input: '.js-birthday-datepicker__input',
    altField: '.js-birthday-datepicker__alt-field'
}, {
    changeMonth: true,
    changeYear: true,
    yearRange: "1900:+nn",
    maxDate: UTCDateToLocalDate(new Date($('.js-birthday-datepicker').attr('data-datepicker-max-date'))),
    minDate: UTCDateToLocalDate(new Date($('.js-birthday-datepicker').attr('data-datepicker-min-date'))),
    numberOfMonths: 1
});

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as modalInputFocus from '../scripts/modalInputFocus';
app.modules.modalInputFocus = modalInputFocus.initInScope;

import * as cart from '../scripts/shop/cart';
app.cart = cart.init;
app.modules.cart = cart.initInScope;

import * as modalOpenSpacing from '../scripts/modalOpenSpacing';
app.modules.modalOpenSpacing = modalOpenSpacing.initInScope;

import * as recaptcha from '../scripts/recaptcha';
app.modules.recaptcha = recaptcha.initInScope;

import * as cookieConsentOverlay from '../scripts/cookie-consent-overlay';
app.modules.cookieConsentOverlay = cookieConsentOverlay.init;