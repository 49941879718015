"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";
import {setServicePermission,getServicePermission} from "./cookiebot-utils";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $cookieConsentButtons = $('.js-consent__accept-btn');

        $cookieConsentButtons.each(function(){
            let $cookieConsentButton = $(this);
            let service = $cookieConsentButton.closest('.js-consent').data('consent-service');
            let type = $cookieConsentButton.closest('.js-consent').data('consent-type');

            if(type === 'iframe'){
                let wrapper = $cookieConsentButton.closest('.js-consent');
                let iframe = wrapper.find('.js-consent__iframe');
                let src = iframe.data('iframe-src');
                let overlay =$cookieConsentButton.closest('.js-consent__overlay');

                if(getServicePermission('marketing')){
                    iframe.prop('src', src);
                    if(overlay.length !== 0){
                        overlay.attr("hidden", "hidden");
                        wrapper.removeClass("consent--box-shadow");
                    }
                }else{
                    if(overlay.length !== 0){
                        overlay.attr("hidden", false);
                        wrapper.addClass("consent--box-shadow");
                    }
                    $cookieConsentButton.on('click', function(e){
                        setServicePermission('marketing');
                        iframe.prop('src', src);
                        if(overlay.length !== 0){
                            overlay.attr("hidden", "hidden");
                            wrapper.removeClass("consent--box-shadow");
                        }
                    });
                }
            }else{
                $cookieConsentButton.on('click', function(e){
                    setServicePermission('marketing');
                    location.reload();
                });
            }
        });
    })
}