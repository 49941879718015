"use strict";

const isMobile = () => matchMedia('(max-width: 767px)').matches;
let isRunning = false;
let $navbarAffix;
let lastScrollPosition = 0;
let isNavOverlayOpen = false;


export function init() {
    $navbarAffix = $('.js-affix-nav');
    updateDefaultTopPosition();

    if ($navbarAffix) {
        navbarToggleAffix($navbarAffix);
    }
}

function navbarToggleAffix($navbarAffix) {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    let scrollTopPosition = $(window).scrollTop();
    if (lastScrollPosition === scrollTopPosition || isNavOverlayOpen) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = scrollTopPosition;
    updateDom();
    requestAnimationFrame(interval);
}

export function updateDom() {
    let defaultOffset = matchMedia('(min-width: 768px)').matches ? 78 : 55;
    let offset = $('.js-affix-nav__placeholder')[0].getBoundingClientRect().top + $('.js-affix-nav__placeholder').height() || defaultOffset;
    let scrollTopPosition = $(window).scrollTop();

    $navbarAffix.isAffix = offset <= scrollTopPosition;

    if ($navbarAffix.isAffix) {
        setAffix();
    } else {
        unsetAffix();
    }
}

export function setAffix() {
    $navbarAffix.addClass('is-affix');
    updateDefaultTopPosition();
}

export function unsetAffix() {
    $navbarAffix.removeClass('is-affix');
    updateDefaultTopPosition();
}
export function updateDefaultTopPosition() {
    if(!isMobile()) {
        let $element = $('.js-affix-nav__top-position-offset');
        let element = $element[0];
        let defaultTopPosition =  element.getBoundingClientRect().top + $element.height();
        $('.js-affix-nav__update-top-position').css('top', defaultTopPosition);
    }
}
