import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = window.app || {};
window.app = app || {};
window.app.modules =  app.modules || {};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import './shared/common';

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as midnight from './scripts/midnight';
app.modules.midnight = midnight.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as fadeIn from './scripts/fadeIn';
app.modules.fadeIn = fadeIn.initInScope;

import * as slider from './scripts/customSlider';
app.modules.slider = slider.initInScope;

import * as thumbSlider from './scripts/thumbSlider';
app.modules.thumbSlider = thumbSlider.initInScope;

import * as fileInput from './scripts/fileInput';
app.modules.fileInput = fileInput.initInScope;

import * as isInViewport from './scripts/isInViewport';
app.modules.isInViewport = isInViewport.initInScope;

import * as tableResponsive from './scripts/tableResponsive';
app.modules.tableResponsive = tableResponsive.initInScope;

import * as heroAnimation from './scripts/hero-animation';
app.heroAnimation = heroAnimation.init;

import * as heroSummerAnimation from './scripts/hero-summer-animation';
app.heroSummerAnimation = heroSummerAnimation.init;

import * as contentTeaserSlider from './scripts/contentTeaserSlider';
app.modules.contentTeaserSlider = contentTeaserSlider.initInScope;

import * as logoSlider from './scripts/logoSlider';
app.modules.logoSlider = logoSlider.initInScope;

import * as hideOnTouch from './scripts/hideOnTouch';
app.modules.hideOnTouch = hideOnTouch.initInScope;

import * as backButton from './scripts/backButton';
app.modules.backButton = backButton.initInScope;

import * as lazyPoster from './scripts/lazyPoster';
app.modules.lazyPoster = lazyPoster.initInScope;

import * as mobileOverlay from './scripts/mobileOverlay';
app.mobileOverlay = mobileOverlay.init;

import * as ajaxFormScroll from './scripts/ajax-form-scroll';
app.modules.ajaxFormScroll = ajaxFormScroll.initInScope;

import * as ajaxFormReset from './scripts/ajax-form-reset';
app.ajaxFormReset = ajaxFormReset.init;

import * as flexview from './scripts/flexview';
app.flexview = flexview.init;

import * as toggleOnSelect from './scripts/toggleOnSelect';
app.modules.toggleOnSelect = toggleOnSelect.initInScope;

import * as accommodationSlider from '@elements/slider';
app.modules.accommodationSlider = accommodationSlider.createInitInScope('.js-accommodation-slider', {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    nextArrow: '<button type="button" class="btn-no-styling slick-next accommodation-slider__arrow" aria-label="Next"></button>',
    prevArrow: '<button type="button" class="btn-no-styling slick-prev accommodation-slider__arrow" aria-label="Previous"></button>',
    swipeToSlide: true
});

let defaultOptions = {
    offset: 150
};

let selectors = {
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
};

import * as dateInput from '@elements/date-input';
app.modules.dateInput = dateInput.initInScope;

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init(defaultOptions, selectors);
smoothScroll.initInScope();

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from './scripts/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as templateItemList from './scripts/templateItemList';
app.modules.templateItemList = templateItemList.initInScope;

import * as duration from './scripts/duration';
app.modules.duration = duration.initInScope;

import * as anchorNav from '@elements/anchor-nav';
app.modules.anchorNav  = anchorNav.createInitInScope({
    list: '.js-anchor-nav',
    listItem: '.js-anchor-nav__item'
},  {
    renderItem: function (text, anchor) {
        return '<li class="anchor-nav__item list-inline-item"><a class="anchor-nav__item-link  js-smoothscroll__link" href="#' + anchor + '">' + text + '</a></li>';
    },
    smoothScroll: true,
    scrollSpy: false,
    scrollSpyOffset: 200
});

import * as tracking from "@elements/tracking";
let options = {}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking


import * as customTracking from './scripts/customTracking';
app.modules.customTracking = customTracking.initInScope;

import * as renderTemplate from './scripts/renderTemplate';
app.modules.renderTemplate = renderTemplate.initInScope;

import * as conditionalForm from './scripts/conditionalForm';
app.modules.conditionalForm = conditionalForm.initInScope;

import * as recaptchaV3 from './scripts/recaptcha-v3';
app.modules.recaptchaV3 = recaptchaV3.initInScope;

import * as acceptCookieService from './scripts/acceptCookieService';
app.modules.acceptCookieService = acceptCookieService.initInScope;

import * as externalVideo from './scripts/externalVideo';
app.modules.externalVideo = externalVideo.initInScope;

import initModulesInScope from "@elements/init-modules-in-scope";

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);