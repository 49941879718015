"use strict";

import {updateDefaultTopPosition} from "./affixNav";

let $notificationHeader;
export function init () {
    $notificationHeader = $('.js-notification-header');
    let notificationName = $notificationHeader.data('notification-header-name') || 'notificationHeader';

    try {
        let data = sessionStorage.getItem(notificationName);
        if (data != 'alreadyShown') {
            $notificationHeader.removeAttr('hidden');
            updateDefaultTopPosition();
        }
    } catch(err) {}

    $('.js-notification-header__close').on('click', function() {
        try {
            sessionStorage.setItem(notificationName, 'alreadyShown');
            $notificationHeader.attr('hidden', '');
            updateDefaultTopPosition();
        } catch(err) {}
    });
}

export function hideNotification() {
    if($notificationHeader) {
        $notificationHeader.css('display', 'none');
    }
}
export function showNotification() {
    if($notificationHeader) {
        $notificationHeader.css('display', 'flex');
    }
}
