"use strict";

export function initInScope($scope) {
    let $item = $scope.find('.js-toggle-on-select');
    let $targets = $scope.find('.js-toggle-on-select__target');

    $item.on('change', function() {
        $targets.attr('hidden', true);
        let $option = $item.find('option:selected');
        if($option[0].className === 'js-toggle-on-select__option') {
            let $target = $($option.attr('data-target'));
            $target.attr('hidden', false);
        }
    });
}
