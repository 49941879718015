"use strict";

import {getDate, setMinDate, setDate} from "@elements/datepicker";

const selectors = {
    base: '.js-duration',
    durationFrom: '.js-duration__input-from',
    durationTo: '.js-duration__input-to',
    durationAdd: '.js-duration__input-add',
    durationAddHideOptional: '.js-duration__input-add-hide-optional',
    result: '.js-duration__result'
}

export function initInScope($scope) {

    $scope.find(selectors.base).each(function () {
        let $base = $(this);
        let $durationFrom = $base.find(selectors.durationFrom);
        let $durationTo = $base.find(selectors.durationTo);
        let $result = $base.find(selectors.result);
        let $durationAddHideOptional = $base.find(selectors.durationAddHideOptional);

        $base.on('change', function() {
            let $durationAdd = $base.find(selectors.durationAdd);

            /* timeout needed for datepicker date-change */
            setTimeout(function() {
                if(getDate($durationTo) && getDate($durationFrom)) {
                    let dateDifference = ((getDate($durationTo).getTime() - getDate($durationFrom).getTime()) / (1000 * 3600 * 24)) + 1;
                    $durationAdd.each(function() {
                        if($(this).closest('.js-conditional-form__part').length === 0 || !$(this).closest('.js-conditional-form__part').attr('hidden')) {
                            let addDays = +$(this).find('option:selected').attr('data-duration-add-days');
                            if(addDays < 1) {
                                dateDifference -= addDays;
                            }
                        }
                    });
                    $result.val(dateDifference);
                    if(getDate($durationTo).getTime() === getDate($durationFrom).getTime()) {
                        $durationAddHideOptional.each(function() {
                           $(this).addClass("d-none");
                        });
                        $result.val("1");
                    } else {
                        $durationAddHideOptional.each(function() {
                            $(this).removeClass("d-none");
                        });
                    }
                }
            }, 300);

        })

    });
}