"use strict";

import $ from 'jquery'
import 'slick-carousel';
import {loadImg} from "@elements/lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils"

export const initInScope = createInitInScope('.js-slider');

export function createInitInScope(selector, options) {
    return function ($scope) {
        let $sliderElements = $scope.find(selector);
        $sliderElements.each(function () {
            let $slider = $(this);
            let $sliderWrapper =  $slider.closest('.js-slider__wrapper');
            let hasArrowPrevText = getPrefixedDataSet('slider', $slider).arrowPrevText || false;
            let hasArrowNextText = getPrefixedDataSet('slider', $slider).arrowNextText || false;

            let prevArrowMarkup = hasArrowPrevText ? `<span class="slider__arrow-text">${hasArrowPrevText}</span>` : '';
            let nextArrowMarkup = hasArrowNextText ? `<span class="slider__arrow-text">${hasArrowNextText}</span>` : '';

            let sliderOptions = {
                infinite: false,
                dots: true,
                nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-down icon-rotate-270"></span></button>' + nextArrowMarkup,
                prevArrow: prevArrowMarkup + '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-down icon-rotate-90"></span></button>',
                slidesToShow: 1,
                swipeToSlide: true,
                sliderWrapper: $slider.closest('.js-slider__wrapper'),
                appendArrows: $sliderWrapper.find('.js-slider__arrow-wrapper'),
                ...options,
                ...getPrefixedDataSet('slider', $slider)
            };

            const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
            const $collapseParent = $slider.closest('.collapse');
            const $modalParent = $slider.closest('.modal');

            if ($tabPaneParent && $tabPaneParent.length) {
                /*wait for the initializiation of the slider if it is hidden in a tab */
                const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');

                $tabTrigger.one('shown.bs.tab', function () {
                    initSlider($slider, sliderOptions);
                });

            } else if ($collapseParent && $collapseParent.length) {
                /* wait until slide is shown if it is initially hidden in a collapse/accordion */
                $collapseParent.one('shown.bs.collapse', function () {
                    initSlider($slider, sliderOptions);
                });

            } else if ($modalParent && $modalParent.length) {
                /* wait until slide is shown if it is initially  hidden in a modal */
                $modalParent.one('shown.bs.modal', function () {
                    initSlider($slider, sliderOptions);
                });

            } else {
                // no tab, accordion, modal -> init slider as usual
                initSlider($slider, sliderOptions);
            }
        });

        return $sliderElements;
    }
}

export function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);

        if(sliderOptions.slideCount) {
            console.log("here");
            $(sliderOptions.sliderWrapper).find('.js-slider__slide-count--sum').text(slick.$slides.length );
            $(sliderOptions.sliderWrapper).find('.js-slider__slide-count--current').text(currentSlide + 1);
        }
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
    return $slider;
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}
