import {getDate, setMinDate, setDate} from "@elements/datepicker";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import $ from "jquery";

const dayInMilliseconds = 24 * 60 * 60 * 1000;

const defaultSelectors = {
    base: '.js-datepicker-range',
    datepickerFrom: '.js-datepicker-range__from',
    datepickerTo: '.js-datepicker-range__to'
};

export function createInitInScope(selectors = defaultSelectors, options = {}) {
    return function ($scope) {
        let $from = $scope.find(selectors.datepickerFrom);

        $from.on('change', function () {
            let $from = $(this);

            let $rangeDatepicker = $(this).closest(selectors.base);
            let elementOptions = {
                ...options,
                ...getPrefixedDataSet('datepicker-range', $rangeDatepicker)
            };


            if (elementOptions.defaultDuration) {
                setDate($rangeDatepicker.find('.js-datepicker-range__to'), roundDate(new Date(getDate($from).getTime() + elementOptions.defaultDuration * dayInMilliseconds)));
            }

            if (elementOptions.minDuration) {
                setMinDate(
                    getToInput($(this),selectors),
                    roundDate(new Date(getDate($from).getTime() + elementOptions.minDuration * dayInMilliseconds))
                );
            } else {
                setMinDate(
                    getToInput($(this),selectors),
                    getDate($(this))
                );
            }
        });

        $from.each(function () {
            let date = getDate($(this));
            if(date){
                setMinDate(getToInput($(this),selectors), date);
            }
        });

        return $scope.find(selectors.base);
    }
}

function getToInput($fromInput, selectors) {
    return $fromInput.closest(selectors.base)
        .find(selectors.datepickerTo)
}

function roundDate(date) {
    if (date) {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }
}

export const initInScope = createInitInScope();
