"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";

export function initInScope ($scope) {
    let $posterImg = $scope.find('.js-lazy-poster');

    let settingsPoster = {
        offset: window.innerHeight
    };


    onEnterViewPort($posterImg, function (posterImg) {
        loadPosterImg($(posterImg));
    }, settingsPoster);
}

export function loadPosterImg($img) {
    $img.attr('poster', $img.data('poster'));
}
