"use strict";
import loadScript from '@elements/load-script';

export function init() {
    if (_config.flexviewAdmin) {
        loadScript("/static/js/libs/flexviewadmin.js").then(() => {
            console.log('flexview admin loaded');
        }).catch(console.error);
    }
}
