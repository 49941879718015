"use strict";

let $overlay;

export function init () {


    $overlay = $('.js-mobile-overlay');

    if (matchMedia('(max-width: 767px)').matches) {
        try {
            let data = sessionStorage.getItem('mobileOverlay');
            if (data != 'alreadyShown') {
                $overlay.removeAttr('hidden');
            }
        } catch(err) {}

        $('.js-mobile-overlay__close, .js-mobile-overlay__link').on('click', function() {
            try {
                sessionStorage.setItem('mobileOverlay', 'alreadyShown');
                $overlay.attr('hidden', '');
            } catch(err) {}
        });
    }
}

export function hideNotification() {
    $overlay.css('display', 'none');
}
export function showNotification() {
    $overlay.css('display', 'flex');
}
