"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-content-teaser-slider__wrapper');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-content-teaser-slider');

            $mainSlider.slick({
                slidesToShow: $mainSlider.data('slider-slides-to-show'),
                slidesToScroll: 1,
                initialSlide: 0,
                nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow content-teaser__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-down icon-rotate-270"></span></button>',
                prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow content-teaser__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-down icon-rotate-90"></span></button>',
                swipeToSlide: true,
                infinite: false,
                sliderWrapper:$container,
                appendArrows: $container.find('.js-slider__arrow-wrapper'),
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1.2,
                            slidesToScroll: 1,
                            infinite: false,
                            initialSlide: 0
                        }
                    }
                ]
            });
        });
    });
}