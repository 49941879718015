"use strict";

import $ from 'jquery'
import 'slick-carousel';
import {loadImg} from "@elements/lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils"

export const initInScope = createInitInScope('.js-thumb-slider');
let sliderId = 0;

export function createInitInScope(selector, options) {
    return function ($scope) {
        let $sliderElements = $scope.find(selector);
        $sliderElements.each(function () {
            let $slider = $(this);
            let $mainSlider = $slider.find('.js-thumb-slider__main');
            let $thumbSlider = $slider.find('.js-thumb-slider__thumbs');
            let id = sliderId++;

            $mainSlider.attr('id', 'main-slider__main-' + id);
            $thumbSlider.attr('id', 'thumb-slider__thumb-' + id);

            let sliderOptions = {
                infinite: false,
                dots: true,
                asNavFor: '#thumb-slider__thumb-' + id,
                nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-down icon-rotate-270"></span></button>',
                prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-down icon-rotate-90"></span></button>',
                slidesToShow: 1,
                swipeToSlide: true,
                sliderWrapper: $slider,
                appendArrows: $slider.find('.js-slider__arrow-wrapper'),
                ...options,
                ...getPrefixedDataSet('slider', $mainSlider)
            };

            let sliderOptionsThumb = {
                infinite: true,
                dots: true,
                slidesToShow: 5,
                asNavFor: '#main-slider__main-' + id,
                swipeToSlide: true,
                centerMode: true,
                centerPadding: '4px',
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 1600,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            };

            initSlider($mainSlider, sliderOptions);
            initSlider($thumbSlider, sliderOptionsThumb);
        });

        return $sliderElements;
    }
}

export function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);

        if(sliderOptions.slideCount) {
            $(sliderOptions.sliderWrapper).find('.js-slider__slide-count--sum').text(slick.$slides.length );
            $(sliderOptions.sliderWrapper).find('.js-slider__slide-count--current').text(currentSlide + 1);
        }
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
    return $slider;
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}
