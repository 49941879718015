"use strict";

export function initInScope ($scope) {
    $scope.find('.js-file-input').on('change', function () {
        var $element = $(this),
            $input = $element.find(':file'),
            $label = $element.find('.js-file-input__text'),
            $btn = $element.find('.js-file-input__btn'),
            numFiles = $input.get(0).files ? $input.get(0).files.length : 1,
            label = $input.val().replace(/\\/g, '/').replace(/.*\//, '');

        if($input.val()) {
            if (numFiles === 0) {
                label = $element.data('file-input-default-text');
            } else if (numFiles > 1) {
                label = $element.data('file-input-multiple-text').replace('[count]', numFiles);
            }

            $label.text(label);
            $btn.addClass('has-file');
        } else {
            $btn.removeClass('has-file');
            $label.text('');
        }


        $btn.on('click', function(evt) {
            evt.preventDefault();
            $input.val('');
            $input.trigger('change');
        })
    });
}
