"use strict";

let width, height, canvas, ctx, circles, animateHeader = true;
let options = {
    'flakeDensity': 2,
    'fallingSpeed': 2
};

export function init() {
    if (matchMedia('(max-width: 767px)').matches) {
        return;
    }

    canvas = document.getElementsByClassName('js-hero-animation__canvas')[0];
    initCanvas(canvas);
    addListeners();
}

function initCanvas(canvas) {
    resize();
    ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, width, height);

    createParticles();
    animate();
}

function createParticles() {
    circles = [];
    let amount = Math.floor(width / (10 / options.flakeDensity));

    for (let x = 0; x < amount; x++) {
        circles.push({
            x: Math.random() * width, //x-coordinate
            y: Math.random() * height, //y-coordinate
            r: Math.random() * 3 + 1 //radius
        });
    }
}

// Event handling
function addListeners() {
    window.addEventListener('scroll', scrollCheck);
    window.addEventListener('resize', resize);
}

function scrollCheck() {
    animateHeader = document.body.scrollTop <= height;
}

function resize() {
    width = canvas.width;
    height = canvas.height;
}

function animate() {
    ctx.clearRect(0, 0, width, height);

    if (animateHeader) {
        drawCircles(circles);
    }
    requestAnimationFrame(animate);
}

function drawCircles() {
    ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
    ctx.beginPath();

    for (let i in circles) {
        let circle = circles[i];
        ctx.moveTo(circle.x, circle.y);
        ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, true);
    }

    ctx.fill();
    let angle = 0;

    for (let i in circles) {
        angle += 0.01;

        let circle = circles[i];
        circle.y += (Math.cos(angle) + 0.5 + circle.r) / (10 / options.fallingSpeed);
        circle.x += Math.sin(angle) * 0.2;

        //Checks if circles has left screen
        if (circle.x > width + 5 || circle.x < -5 || circle.y > height) {

            if (i % 3 > 0) {
                circles[i] = {
                    x: Math.random() * width,
                    y: -10,
                    r: circle.r,
                    d: circle.d
                };

            }
        }
    }
}
