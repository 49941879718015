"use strict";

import {trackEvent} from "@elements/tracking/src/gtm";
import {getPrefixedDataSet} from "@elements/data-set-utils";

export function initInScope($scope) {
    $scope.find('.js-tracking--on-change').on('change', function () {
        let $this = $(this);
        let selectedOption = $this.find(':selected').text();
        let data = {
            ...getPrefixedDataSet('tracking', $this),
            "label": selectedOption,
        };

        trackEvent(data, {debug: true});
    });


    //is needed to track external iframe
    $(window).on('blur', function () {
        if(document.activeElement.classList.contains('js-tracking--on-blur')) {
            let $this = $(document.activeElement);
            let data = {
                ...getPrefixedDataSet('tracking', $this)
            };
            trackEvent(data, {debug: true});
        }
    });
}