"use strict";

import {scrollTo, focus} from "./scrollTo";

export function initInScope($scope) {
    let $ajaxForm = $('.js-ajax-form');
    $ajaxForm.on('submit.ajax-form', function() {
        let $wrapper = $(this);
        scrollToResult($wrapper);
    });

    let $ajaxLink = $('.js-ajax-form__link');
    $ajaxLink.on('click', function() {
        let $wrapper = $(this).closest('.js-ajax-form');
        scrollToResult($wrapper);
    })
}

function scrollToResult($wrapper) {
    let offset = 100;
    let $target = $wrapper.find('.js-ajax-form__result');
    scrollTo($target, offset, function () {
        focus($target);
    });
}