"use strict";

import $ from 'jquery';
import loadJQueryUi from '@elements/load-jquery-ui';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";

function addKeyboardSupport($datepicker, $altField) {
    let newDate = localDateToUTCDate($datepicker.datepicker('getDate'));

    $datepicker.on('keydown', function (evt) {
        if(newDate == null) {
            $datepicker.datepicker('setDate', new Date());
            newDate = $datepicker.datepicker('getDate');
        }

        switch (evt.key) {
            case 'ArrowLeft':
                newDate.setDate(newDate.getDate() - 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowUp':
                newDate.setDate(newDate.getDate() - 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowRight':
                newDate.setDate(newDate.getDate() + 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowDown':
                newDate.setDate(newDate.getDate() + 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageUp':
                newDate.setDate(newDate.getDate() - 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageDown':
                newDate.setDate(newDate.getDate() + 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'Enter':
                newDate.setDate(newDate.getDate());
                $datepicker.datepicker('setDate', newDate);
                $altField.val(dateToISOString(localDateToUTCDate(newDate))).trigger('change');
                $datepicker.datepicker('hide');

                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
        }

        if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
            $datepicker.datepicker('setDate', newDate);
        }
    });
}

// returns UTC Date
export function getDate($datepicker) {
    return ISOStringToDate($datepicker.find('.js-birthday-datepicker__alt-field').val());
}

// date: UTC DATE
export function setDate($datepicker, date) {
    loadJQueryUi().then(function () {
        console.log( $datepicker.find('.js-birthday-datepicker__alt-field'),  $datepicker.find('.js-birthday-datepicker__input'));
        $datepicker.find('.js-birthday-datepicker__alt-field').val(dateToISOString(date));
        $datepicker.find('.js-birthday-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
    });

}

// date: UTC DATE
export function setMinDate($datepicker, date) {
    loadJQueryUi().then(function () {
        // todo do not use get time but the alt field
        if ($datepicker.find('.js-birthday-datepicker__input').val() && $datepicker.find('.js-birthday-datepicker__input').datepicker('getDate').getTime() < date.getTime()) {
            $datepicker.find('.js-birthday-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-birthday-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find('.js-birthday-datepicker__input')
            .datepicker('option', 'minDate', UTCDateToLocalDate(date));
    });
}

// date: UTC DATE
export function setMaxDate($datepicker, date) {
    console.log("setmaxdate");
    loadJQueryUi().then(function () {
        // todo do not use get time but the alt field
        if ($datepicker.find('.js-birthday-datepicker__input').val() && $datepicker.find('.js-birthday-datepicker__input').datepicker('getDate').getTime() > date.getTime()) {
            console.log($datepicker, $datepicker.find('.js-birthday-datepicker__input').val());
            $datepicker.find('.js-birthday-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-birthday-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find('.js-birthday-datepicker__input')
            .datepicker('option', 'maxDate', UTCDateToLocalDate(date));
    });
}

export function createInitInScope(selectors, defaultOptions) {
    return function ($scope) {
        let $datepickerContainers = $scope.find(selectors.base);
        loadJQueryUi().then(function () {
            $datepickerContainers.each(function () {
                let $container = $(this);
                let $datepicker = $container.find(selectors.input);
                let $altField = $container.find(selectors.altField);

                let options = {
                    changeMonth: true,
                    changeYear: true,
                    yearRange: "1900:+nn",
                    numberOfMonths: 1,
                    minDate: UTCDateToLocalDate(new Date()),
                    nextText: '<span class="icon icon-arrow"></span>',
                    prevText: '<span class="icon icon-arrow"></span>',
                    firstDay: 1,
                    showAnim: 'show', // other animations (like fadeIn) do not work with jquery.slim
                    onSelect: function(dateString, inst) {
                        let selectedDate = new Date(Date.UTC(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
                        $altField.val(dateToISOString(selectedDate)).trigger('change');
                    },
                    beforeShow: function(input, inst) {
                        //needed for firefox changeMonth + changeYear bug
                        $(document).off('focusin.bs.modal');
                    },
                    onClose:function(){
                        //needed for firefox changeMonth + changeYear bug
                        $(document).on('focusin.bs.modal');
                    },
                    ...defaultOptions,
                    ...transformDataOptions(getPrefixedDataSet('datepicker', $container))
                };

                addKeyboardSupport($datepicker, $altField);
                $datepicker.datepicker(options);

                if ($altField.val()) {
                    console.log("SET_DATE", $altField.val(), $container, ISOStringToDate($altField.val()));
                    setDate($container, ISOStringToDate($altField.val()));
                }

                $altField.on('change', () => {
                    setDate($container, ISOStringToDate($altField.val()));
                });
            });
        });
    }
}

export const initInScope = createInitInScope({
    base: '.js-birthday-datepicker',
    input: '.js-birthday-datepicker__input',
    altField: '.js-birthday-datepicker__alt-field'
});

function transformDataOptions(options) {
    options = {...options};

    if (options.minDate && typeof options.minDate === 'string') {
        options.minDate = UTCDateToLocalDate(ISOStringToDate(options.minDate));
    }

    if (options.maxDate && typeof options.maxDate === 'string') {
        options.maxDate = UTCDateToLocalDate(ISOStringToDate(options.maxDate));
    }

    return options;
}