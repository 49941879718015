"use strict";

import loadScript from '@elements/load-script';

export function initInScope ($scope) {
    if(matchMedia('(min-width: 768px)').matches) {
        loadScript("/static/js/libs/midnight.jquery.min.js").then(() => {
            let $items = $scope.find('.js-midnight');
            $items.each(function () {
                $(this).midnight();
            });
        }).catch(console.error);
    }
}
