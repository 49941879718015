import {hideNotification, showNotification} from "./notificationHeader";

const isMobile = () => matchMedia('(max-width: 767px)').matches;
import {setAffix, unsetAffix, updateDom} from "./affixNav";
let alreadyHidden;

export function init() {
    $('.js-simple-nav').each(function () {

        let $nav = $(this);
        let $toggle = $nav.find('.js-simple-nav__toggle');
        let $collapse = $nav.find('.js-simple-nav__collapse');

        // todo aria attributes
        $toggle.on('click', () => {
            console.log("click");
            console.log("CLICK");

            $collapse.toggleClass('is-open');
            $toggle.toggleClass('is-open');
            if($toggle.hasClass('is-open') && isMobile()) {
                setAffix();
                hideNotification();
                $('body').css('overflow', 'hidden');
            } else if(!$toggle.hasClass('is-open') && isMobile()) {
                showNotification();
                unsetAffix();
                $('body').css('overflow', 'auto');
            }
        });

        $nav.find('.js-simple-nav__list').each(function () {
            let $list = $(this);
            let $listItems = $list.find('.js-simple-nav__list-item');
            let $itemToggles = $list.find('.js-simple-nav__list-toggle');
            let $backBtn = $list.find('.js-simple-nav__back');

            $backBtn.on('click', function(evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                let levelTwoItems = $('.simple-nav__item--level-2');
                let levelOneItems = $('.simple-nav__item--level-1');
                let levelZeroItems = $('.simple-nav__item--level-0');
                alreadyHidden = false;

                checkLevelToGoBack(levelTwoItems);
                checkLevelToGoBack(levelOneItems);
                checkLevelToGoBack(levelZeroItems);
            });

            $itemToggles.on('click', function (evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation();
                let $parentItem = $(this).closest('.js-simple-nav__list-item');
                if ($parentItem.hasClass('is-open')) {
                    if (isMobile()) {
                        hideItem($parentItem);
                    } else {
                        hideItem($listItems);
                        removeBodyClickHandler(bodyClickHandler);
                    }
                } else {
                    if (isMobile()) {
                        showItem($parentItem);
                    } else {
                        hideItem($listItems);
                        showItem($parentItem);
                        addBodyClickHandler(bodyClickHandler);
                    }

                }
            });

            function bodyClickHandler(evt) {
                if (!$(evt.target).closest('.js-simple-nav').length) {
                    evt.preventDefault();
                    hideItem($listItems);
                    removeBodyClickHandler(bodyClickHandler);
                }
            }
        })
    });
}

function addBodyClickHandler(bodyClickHandler) {
    $(document.body).on('click', bodyClickHandler);
}

function removeBodyClickHandler(bodyClickHandler) {
    $(document.body).off('click', bodyClickHandler);
}


function hideItem($item) {
    $item.removeClass('is-open').attr('aria-expanded', false);
}

function showItem($item) {
    $item.addClass('is-open').attr('aria-expanded', true);
}


function checkLevelToGoBack(level) {
    if(!alreadyHidden) {
        level.each(function() {
            let $item = $(this);
            if($item.hasClass('is-open')) {
                hideItem($item);
                alreadyHidden = true;
            }
        });
    }
}